<template>
  <main-container :icon="$route.meta.icon" :title="$route.meta.title">
    <template v-slot:tools>

      <span v-if="$vuetify.breakpoint.name == 'xs' || $vuetify.breakpoint.name == 'sm'">
          <emc-info-help title="Informações" text="Os Log's de Integrações ficarão disponíveis por 18 meses!" />
      </span>
      <span v-else>
          <v-icon  color="primary" small>mdi-information-outline</v-icon><strong class="subtitle-1 ml-1  ">Os Log's de Integrações ficarão disponíveis por 18 meses!</strong>
      </span>
       
      </template>

    <EmcLoaderProgress :show.sync="loading" />
    <v-row>
      <v-col cols="12" sm="9">
        <emc-util-scroll class="ma-2" maxHeight="500px" overflow-x="hidden">
        <v-row>
        <v-col :id="'item-' + i" cols="12" sm="4" v-for="(item, i) in integrations" :key="i" >
            <template>
              <v-sheet outlined :color="item.is_running ? 'orange darken-1' : 'grey lighten-1'" rounded>
                <v-card class="mx-auto" elevation="0">
                  <v-list-item three-line>

                    <v-list-item-content>
                      <div class="text-overline mb-4">
                        <v-icon :color="direction[item.direction_integration].color">{{ direction[item.direction_integration].icon }}</v-icon> {{ item.name }}
                      </div>
                      <v-list-item-title class="text-h4 mb-1" style="cursor: pointer;" v-if="item.direction_integration == 'U'" @click="showIntegrationProcessItems(item.id, item.name);">
                        
                        <span v-if="renderComponent">
                            <v-progress-circular
                              v-if="itemIndex == item.id || !indexIds.includes(item.id)"
                              :width="2"
                              color="success"
                              indeterminate
                            />
                            <span v-else>{{ item.total_items }} </span> <small class="caption">itens pendentes</small>
                        </span>

                      </v-list-item-title>
                      <v-list-item-subtitle>{{ item.description }}</v-list-item-subtitle>
                    </v-list-item-content>

                    <v-list-item-icon
                        tile
                        size="80"
                        color="grey"
                    >
                      <v-icon color="purple" x-large>{{ item.icon }}</v-icon>
                    </v-list-item-icon>
                  </v-list-item>

                  <v-card-actions>

                    <v-container v-if="isAnima()">
                      <v-row>
                        <v-col v-if="![43,53].includes(item.id)">
                          <sys-select-brand
                              :model.sync="brand_id"
                              label="Marca"
                              clearable
                              dense
                          />
                        </v-col>
                        <v-col v-if="[47,49,44].includes(item.id)">
                          <sys-select-category
                              :model.sync="category_id"
                              label="Categoria Curso"
                              clearable
                              dense
                          />
                        </v-col>
                        <v-col v-if="item.id == 43">
                          <sys-select-join-category
                              :model.sync="join_category_id"
                              label="Tipo de Junção"
                              clearable
                              dense
                          />
                        </v-col>
                      </v-row>
                      <v-row v-if="[47,49,44,43,53].includes(item.id)">
                        <v-col cols="12" sm="4" v-if="isAnima()">
                          <v-select
                                v-model="actions"
                                item-value="id"
                                item-text="name"
                                :items="actionItems"
                                label="Ação"
                                multiple
                                clearable
                            />
                        </v-col>
                        <v-col cols="12" sm="8">
                          <s-switch inset disabled v-model="multiLot" label="Múltiplos Lotes"/>
                        </v-col>
                      </v-row>
                    </v-container>
                    
                    <v-spacer></v-spacer>

                  </v-card-actions>

                  <v-divider :class="item.is_running ? 'orange darken-1' : 'grey lighten-1'"></v-divider>

                  <v-card-actions>

                    <emc-button-icon 
                      color="green"
                      text="Integrar"
                      icon="mdi-play-circle-outline"
                      :loading="(loadingRun && item.id == itemSelected) || item.is_running" 
                      :disabled="item.is_auto" 
                      @click.stop="questionIntegration(item)"
                    />
                    
                    <small class="ml-1" v-if="item.is_auto">Automático</small>

                    <v-spacer></v-spacer>

                    <emc-button-icon icon='mdi-refresh' text="Atualizar" color="success" :loading="loadingRefresh && itemIndex == item.id" @click.prevent.stop="getData(item.id)" />
                    
                    <span>
                        <emc-excel-import-icon v-if="item.id == 47 || item.id == 2"   show-clear-items param="integration_class_schedules_week" />
                        <emc-excel-import-icon v-if="item.id == 49"                   show-clear-items param="integration_class_schedules_date" />
                        <emc-excel-import-icon v-if="item.id == 44 || item.id == 11"  show-clear-items param="integration_attributions" />
                        <emc-excel-import-icon v-if="item.id == 50"                   show-clear-items param="integration_ambience" />
                        <emc-excel-import-icon v-if="item.id == 13"                   show-clear-items param="integration_ambience_link" />
                        <emc-excel-import-icon v-if="item.id == 46"                   show-clear-items param="integration_ambience_link_week" />
                        <emc-excel-import-icon v-if="item.id == 51"                   show-clear-items param="integration_ambience_link_date" />
                        <emc-excel-import-icon v-if="item.id == 3 || item.id == 43"   show-clear-items param="integration_join" />
                        <emc-excel-import-icon v-if="item.id == 53 || item.id == 64"  show-clear-items param="integration_activity_item" />
                        <emc-excel-import-icon v-if="item.id == 1"                    show-clear-items param="integration_grids" />
                        <emc-excel-import-icon v-if="item.id == 54"                   show-clear-items param="integration_time_table_grids" />
                        <emc-excel-import-icon v-if="item.id == 8"                    show-clear-items param="integration_groups" toolTip="Importar Turmas" />
                        <emc-excel-import-icon v-if="item.id == 8"                    show-clear-items param="integration_sub_groups" toolTip="Importar SubTurmas" color="primary"  />
                    </span>

                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn v-bind="attrs" v-on="on" icon @click.stop="processNumber = null; showDetailsIntegration(item, 1); icon = item.icon; title = `DETALHES: ` + item.name">
                            <v-icon>mdi-card-text-outline</v-icon>
                          </v-btn>
                        </template>
                        <span> Mais informações </span>
                    </v-tooltip>

                    <!--
                    <v-btn
                        icon
                        @click="show = !show; position = i"
                    >
                      <v-icon>{{ show  && position == i ? 'mdi-chevron-up' : 'mdi-chevron-down' }}</v-icon>
                    </v-btn>
                    -->
                  </v-card-actions>

                  <!--
                  <v-expand-transition>
                    <div v-show="show && position == i">
                      <v-divider></v-divider>

                      <v-card-text>
                        <v-list flat subheader three-line>
                          <v-list-item v-for="(error, i) in errors" :key="i">
                            <v-list-item-content>
                              <v-list-item-title v-text="error.name"></v-list-item-title>
                              <v-list-item-subtitle>{{ error.description }}</v-list-item-subtitle>
                            </v-list-item-content>

                            <v-list-item-action>
                              <v-list-item-action-text v-text="error.amount"></v-list-item-action-text>
                              <v-icon :color="error.color" small>{{ error.icon}}</v-icon>
                            </v-list-item-action>
                          </v-list-item>
                        </v-list>
                      </v-card-text>
                    </div>
                  </v-expand-transition>
                  -->
                </v-card>
              </v-sheet>
            </template>
          </v-col>
        
        </v-row>
      </emc-util-scroll>
      </v-col>
      <v-col cols="12" sm="3">
        <v-card class="mx-auto" elevation="6">
          
          <v-list-item>

            <!-- <v-list-item-content> -->
              
              <v-list-item-title>
                <span class="text-overline">
                    Integrações
                </span>
                  <v-icon small :color="direction['U'].color">{{ direction['U'].icon }}</v-icon><small> UP</small>
                  <v-icon class="ml-1" small :color="direction['D'].color">{{ direction['D'].icon }}</v-icon><small> DOWN </small>
              </v-list-item-title>

              <!-- <v-list-item-title class="text-caption mb-1">
                Executar todas as integrações
              </v-list-item-title> -->
            <!-- </v-list-item-content> -->

          </v-list-item>

          <!-- <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn fab small color="green" :disabled="true">
              <v-icon color="white">mdi-play-circle-outline</v-icon>
            </v-btn>
          </v-card-actions> -->

          <v-divider></v-divider>

          <v-card-text>
            <v-list dense>
              <emc-util-scroll maxHeight="410px">
                <v-list-item v-for="(item, i) in integrations" :key="i">
                  <v-list-item-content>
                  
                    <v-list-item-title v-bind:class="['text-caption', { 'orange--text darken-1' : item.is_running }]">
                      <v-icon small :color="direction[item.direction_integration].color">{{ direction[item.direction_integration].icon }}</v-icon>
                      <v-icon :color="item.is_running ? 'orange darken-1' : ''" small v-bind:class="{'mdi-spin' : item.is_running }">{{ item.is_running ? 'mdi-loading' : item.icon}}</v-icon>
                      <a class="ml-1" :href="'#item-' + i" style="text-decoration: none; color: black;">{{ item.name }}</a> 
                    </v-list-item-title>
                  </v-list-item-content>

                </v-list-item>
              </emc-util-scroll>
            </v-list>
          </v-card-text>

        </v-card>
      </v-col>
    </v-row>

    <emc-modal-form v-bind:show.sync="showIntegrationPencences" v-bind:title="titleIntegrationProcessItems">
      <template v-slot:tools>
        <v-btn :color="filterDisabled ? 'primary' : 'secundary'" small @click="filterDisabled = !filterDisabled">{{ filterDisabled ? 'Ocultar' : 'Exibir' }} itens desmarcados</v-btn>
      </template>
      <template v-slot:form>
        <v-card elevation="0">
          <v-card-title>
            Relação de itens
            <v-spacer></v-spacer>
            <v-text-field
                v-model="searchIntegrationProcessItem"
                append-icon="mdi-magnify"
                label="Pesquisar..."
                single-line
                hide-details
            ></v-text-field>
          </v-card-title>
        <v-data-table
            v-model="selectedIntegration"
            :headers="headersIntegration"
            :items="filteredIntegrationProcessItems"
            :search="searchIntegrationProcessItem"
            item-key="id"
            show-select
            :items-per-page="5"
            class="elevation-1"
            :loading="loadingIntegrationProcessItems"
            loading-text="Carregando..."
        >
        </v-data-table>
        <div class="float-left mt-3">
          <strong>{{ filteredIntegrationProcessItems.length }}</strong> itens selecionados para integração.
        </div>
        <div class="float-right mt-3">
          <v-btn @click.stop="saveIntegrationProcessItems()" color="primary" :disabled="loadingSavingIntegrationProcessItems" :loading="loadingSavingIntegrationProcessItems">
            Salvar <v-icon right dark>mdi-content-save</v-icon>
          </v-btn>
        </div>
        </v-card>
      </template>
    </emc-modal-form>

    <emc-modal-form v-bind:show.sync="show" v-bind:title="title" v-bind:icon="icon">
      
      <template v-slot:tools>

        <v-responsive
          class="mx-auto"
          max-width="200"
        >

          <v-text-field
              v-model="processNumber"
              label="Núm. Processo"
              append-icon="search"
              dense
              class="mt-4"
              @click:append="showDetailsIntegration(selectedItemProcess, 1)"
          />

        </v-responsive>

        

      </template>
      
      <template v-slot:form>
        <EmcLoaderProgress :show.sync="loadingShow" />
          <v-expansion-panels v-model="panel">
            <v-expansion-panel v-for="(item,i) in process" :key="i">
              <v-expansion-panel-header disable-icon-rotate>
                <span>
                  Processo: {{ item.id }}
                  <span v-if="item.auto || item.user_creator">
                    <br/>
                    <small v-if="item.auto">
                      <v-icon color="success" small>mdi-av-timer</v-icon>
                      Automático
                    </small>
                    <small v-else-if="item.user_creator">
                      <v-icon color="primary" small>mdi-account-tie-outline</v-icon>
                      {{ item.user_creator.name }}
                    </small>
                  </span>
                  <br/>
                  <small>{{ item.created_at | dateTime }} ({{ item.created_at_human }})</small>
                </span>
                
                <template v-slot:actions>

                  <v-chip
                      :color="item.status.color"
                      label
                      outlined
                  >
                    
                    <v-icon small left :color="item.status.color">
                      {{ item.status.icon }}
                    </v-icon>
                    {{ item.status.name }}

                    <emc-excel-export-icon report-id="71" :params="'id=' + item.id" />

                    <v-tooltip v-if="isAnima() && item.url_file_name" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn :disabled="item.import_status_id <= 2" v-bind="attrs" small v-on="on" icon :href="item.url_file_name + '?token=' + token" target="_blank">
                                <v-icon small color="cyan">mdi-code-json</v-icon>
                            </v-btn>
                        </template>
                        <span> Download Json </span>
                    </v-tooltip>

                    <v-tooltip class="ml-2" v-if="isAnima() && ![50,47,49,46,51].includes(item.integration_item_id) && (item.integration_process_status_id == 1 || item.integration_process_status_id == 2)" bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-btn small color="success darken-1" icon @click.prevent.stop="getResponse(item)" :loading="loadingResponse && selectedItem.id == item.id" v-bind="attrs" v-on="on"><v-icon>mdi-refresh-circle</v-icon></v-btn>
                        </template>
                        <span> Processar Retorno </span>
                    </v-tooltip>

                    <emc-info-help v-if="item.exception" show-copy icon="mdi-alert-rhombus-outline" title="Detalhes do erro" :text="item.exception" />

                  </v-chip>

                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <template>
                  <v-alert dense outlined type="error" v-if="item.message_response || item.exception">{{ item.message_response ? item.message_response : item.exception }}</v-alert>
                  <div v-if="!isAnima()" class="text-center">
                    <v-chip class="ma-2" color="success" outlined>
                      Total com sucesso: {{ item.success_amount }}
                    </v-chip>
                    <v-chip class="ma-2" color="error" outlined @click.stop="filterFailed = !filterFailed">
                      Total com erro: {{ item.errors_amount }}
                    </v-chip>
                    <v-chip class="ma-2" color="warning" outlined>
                      Total restante: {{ remainingTotal(item)  }}
                    </v-chip>
                  </div>
                  <v-card>
                    <v-card-title>
                      <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Pesquisar"
                          single-line
                          hide-details
                      ></v-text-field>
                    </v-card-title>
                    <v-data-table 
                      :loading="loadingItems" 
                      :search="search" 
                      :headers="headers" 
                      :items="item.items ? item.items.filter(f => { if(!filterFailed) { return f.success == 0 } else { return true } }) : []" 
                      :items-per-page="10" 
                      class="elevation-1"
                    >
                     
                    </v-data-table>
                  </v-card>
                </template>
              </v-expansion-panel-content>
            </v-expansion-panel>

            <v-pagination
              v-model="page"
              class="my-4"
              @input="showDetailsIntegration(selectedItemProcess, page)"
              :length="totalPages"
          />

          </v-expansion-panels>


      </template>
    </emc-modal-form>

    <emc-alert-modal
        v-bind:show.sync="showAlert"
        :icon="error.icon"
        :title="error.title"
        :color="error ? `error` : `success`"
        colorIcon="white"
        classTitle="white--text title-1"
        textCancel="OK"
    >
      <template v-slot:text>
        <span class="subtitle-2 text-center">{{ error.message || message }}</span>
      </template>
    </emc-alert-modal>

    <emc-alert-modal text="Confirma a integração dos itens?" v-bind:show.sync="showQuestionIntegration">
          <template v-slot:actions>
              <v-btn color="green" @click.prevent.stop="runIntegration(selectedItemIntegration)" >SIM - Integrar <v-icon right dark>mdi-play-circle-outline</v-icon></v-btn>
          </template>
      </emc-alert-modal>

  </main-container>
</template>

<script>

import { mapActions, mapState } from 'vuex'

export default {
    name: 'IntegrationProcessIndex',
  data () {
      return {
        panel: null,
        filterFailed: true,
        show: false,
        page: 1,
        totalPages: 1,
        loadingItems: false,
        message: '',
        search: '',
        selectedItem: null,
        selectedItemIntegration: null,
        showQuestionIntegration: false,
        loadingResponse: false,
        renderComponent: true,
        processNumber: null,
        indexIds: [],
        actionItems: [
          { id: 'C', name: 'I' },
          { id: 'U', name: 'U' },
          { id: 'D', name: 'D' },
        ],
        actions: [],
        brand_id: null,
        category_id: null,
        join_category_id: null,
        multiLot: false,
        searchIntegrationProcessItem: '',
        selectedItemProcess: null,
        position: null,
        loading: false,
        loadingShow: false,
        loadingRun: false,
        loadingIntegrationProcessItems: true,
        loadingSavingIntegrationProcessItems: false,
        itemIndex: -1,
        integrations: [],
        direction: {
          U: { icon: 'mdi-arrow-up-bold-box',   color: 'green' },
          D: { icon: 'mdi-arrow-down-bold-box', color: 'primary' }
        },
        headers: [
          { text: 'Código externo', align: 'start', sortable: false, value: 'code'},
          { text: 'Código interno', align: 'start', sortable: false, value: 'model_id'},
          { text: 'Descrição', align: 'start', sortable: false, value: 'description'},
          { text: 'Criado em', align: 'start', sortable: false, value: 'created_at', width: "30px"},
          { text: 'Ação', align: 'start', sortable: false, value: 'type_integration'},
          { text: 'Mensagem', align: 'start', sortable: true, value: 'exception'}
        ],
        itemSelected: null,
        showAlert: false,
        title: '',
        icon: '',
        showIntegrationPencences: false,
        titleIntegrationProcessItems: '',
        loadingRefresh: false,
        process: [],
        error: '',
        errors: [
          { name: 'Total de itens', amount: 100, icon: 'mdi-progress-upload', color: 'grey', description: 'Todos os últimos itens enviados para integração.' },
          { name: 'Total de erros', amount: 20, icon: 'mdi-progress-close', color: 'red', description: 'Quantidade de erros gerados no último processo de integração.' },
          { name: 'Total de itens integrados', amount: 80, icon: 'mdi-progress-check', color: 'green', description: 'Total de itens integrados corretamente.'}
        ],
        selectedIntegration: [],
        integrationProcessItems: [],
        headersIntegration: [
          {
            text: 'ID interno',
            sortable: false,
            value: 'model_id',
          },
          { text: 'ID Externo', value: 'code' },
          { text: 'Descrição', value: 'description' },
          { text: 'Tipo de integração', value: 'type_integration'}
        ],
        removeIntegrationProcessItem: [],
        addIntegrationProcessItem: [],
        filterDisabled: false
      }
    },
    computed: {
        ...mapState('auth', ['token']),
        filteredIntegrationProcessItems() {
          return this.integrationProcessItems.filter((item) => item.must_integration != this.filterDisabled )
        }
      },
    methods: {
      ...mapActions('integration', ['ActionIndexIntegrationItemsInstitution', 'ActionRunIntegration', 'ActionIntegrationProcess', 'ActionIndexIntegrationProcessItemsType', 'ActionUpdateIntegrationProcessItems', 'ActionIntegrationProcessGetResponse', 'ActionIntegrationProcessFind']),
      
      loadItems(index)
      { 

        let process = this.process[index];

        if(!process){
          return;
        }

        process.items = [];
        this.loadingItems = true;
        
        this.ActionIntegrationProcessFind({ id: process.id })
            .then((res) => {
              this.process[index] = res.data;
            })
            .catch((error) => {
             
            })
            .finally(() => {
              this.loadingItems = false;
            });
      },

      remainingTotal(item){

        let total = item.items_amount - (item.success_amount + item.errors_amount);

        if(total >= 0){
          return total;
        }else{
          return 0;
        }

      },

      questionIntegration(item){
        this.selectedItemIntegration = item;
        this.showQuestionIntegration = true;
      },  

      getResponse(item)
      {
        
        this.loadingResponse = true;
        this.selectedItem = item;

        let payload = {
          id: item.id,
        };
        
        this.ActionIntegrationProcessGetResponse(payload)
            .then((res) => {
              
            })
            .catch((error) => {
             
            })
            .finally(() => {
              this.showDetailsIntegration({ id: item.integration_item_id}, this.page);
              this.loadingResponse = false;
            })
      },
      
      showIntegrationProcessItems(integrationItemId, integrationItemName)
      {
        this.titleIntegrationProcessItems = integrationItemName + ' - Itens pendente de integração'
        this.loadingIntegrationProcessItems = true
        this.showIntegrationPencences = true
        this.filterDisabled = false

        this.ActionIndexIntegrationProcessItemsType({ integration_item_id: integrationItemId })
            .then((r) => {
                this.integrationProcessItems = r.data
                this.selectedIntegration = r.data.filter(item => item.must_integration)
            })
            .finally(() => {
              this.loadingIntegrationProcessItems = false
            })
      },
      saveIntegrationProcessItems()
      {
        this.loadingSavingIntegrationProcessItems = true
        const params = {
          remove: this.removeIntegrationProcessItem,
          add: this.addIntegrationProcessItem
        }

        this.ActionUpdateIntegrationProcessItems(params)
            .then((r) => {
                this.removeIntegrationProcessItem = []
                this.addIntegrationProcessItem = []
            })
            .finally(() => {
              this.loadingSavingIntegrationProcessItems = false
              this.showIntegrationPencences = false
              this.getData();
             
            })
      },
      showDetailsIntegration(item, page)
      {
          this.panel = null;
          this.process = [];
          this.show = true;
          this.loadingShow = true;
          this.selectedItemProcess = item;

          this.page = page;

          let params = {
            id:       item.id,
            page:     page,
            per_page: 5,
          }

          if(this.processNumber){
            params.find_columns = {
              id: this.processNumber
            };
          }



          this.ActionIntegrationProcess(params)
            .then((res) => {
                this.process    = res.data,
                this.totalPages = res.meta.last_page;
            })
            .finally(() => {
              this.loadingShow = false
            })
      },
      getData(id = null) {
        
        if(id){
          this.itemIndex = id;
          this.loadingRefresh = true;
        }else{
          this.loading = true;
          this.integrations = [];
          this.indexIds = [];
        }

        let payload = {};

        if(id){
          payload = { 
            find_columns: { id: id },
            withTotalItems: true
           };
        }else{
          payload.withTotalItems = false;
        }

        this.ActionIndexIntegrationItemsInstitution(payload)
          .then((res) => {
            
            if(id && res.data.length > 0){
              
              let item = this.integrations.find(i => i.id == id);

              if(item){
                let index = this.integrations.indexOf(item);
                this.integrations[index] = res.data[0];
                this.forceRerender();
              }

            }else{
              this.integrations = res.data;
            }

          })
          .finally(() => {
            
            this.loading = false;
            this.loadingRefresh = false;
            this.itemIndex = -1;
            
            if(!id){
              this.integrations.forEach(i => {
                this.getData(i.id);
              });
            }else{
              this.indexIds.push(id);
            }

          })

      },

      forceRerender () {
            
          this.renderComponent = false;
  
          this.$nextTick(() => {
              this.renderComponent = true;
          });
  
        },

      runIntegration(item) {
        
        this.itemSelected = item.id;
        this.loadingRun = true;
        this.showAlert = false;
        this.message = '';
        this.error = '';
        this.showQuestionIntegration = false;
        
        let payload = {
          id: item.id,
          params: {
            brand_id:         this.brand_id,
            category_id:      this.category_id,
            join_category_id: this.join_category_id,
            multiLot:         this.multiLot,
            actions:          this.actions,
          }
        }

        this.ActionRunIntegration(payload)
          .then((r) => {
              item.is_running = true;
              this.message = r.message;
              this.showAlert = true;
          })
          .catch((e) => {
            this.showAlert = true
            this.error = {
              title: 'Integração',
              icon: 'mdi-transfer',
              message: e.message
            }
          })
          .finally(() => {
            this.itemSelected = null
            this.loadingRun = false
          })

      }
    },
    created() {
      this.getData();
    },
    watch: {
      
      panel(val)
      {
        if(val === 0 || val >= 0){
          this.loadItems(val);
        }
      },

      showAlert(item) {
        if(!item) {
          // this.getData();
        }
      },
      showIntegrationPencences(show)
      {
        if(!show) {
          this.selectedIntegration = []
          this.integrationProcessItems = []
        }
      },

      show(val){

        if(val){
          this.processNumber = null;
        }

      },

      selectedIntegration(newItem, oldItem)
      {
        
        
        this.removeIntegrationProcessItem = [];
        this.addIntegrationProcessItem = [];

        this.addIntegrationProcessItem = newItem;

        this.integrationProcessItems.forEach(item => {

          let find = this.addIntegrationProcessItem.find(item2 => item2.id == item.id);

          if(!find){
            this.removeIntegrationProcessItem.push(item);
          }

        });
        
      }
    }
}
</script>